import React from 'react';
import './style.css';
// import Home from './Home';
// import About from './About';
// import Contact from './Contact';
import { Route, Routes } from "react-router-dom";

// import Navbar from './Navbar';

// const Navbar = React.lazy(() => import("./Navbar"));

// const Sidebar = React.lazy(() => import("./sidebar"));


import Loading from './loading';


const Blogs = React.lazy(() => import("./blogs"));

const BlogDetails = React.lazy(() => import('./blogdetails')); // Import the BlogDetails component

const Edit = React.lazy(() => import("./edit"));

const Create = React.lazy(() => import("./create"));

const Login = React.lazy(() => import('./login'));

const Register = React.lazy(() => import("./register"));



const Pannel = React.lazy(() => import("./adminpannel"));

const Cpannel = React.lazy(() => import('./adminuserpannel'))

const Notfound = React.lazy(() => import("./notfound"));

const About = React.lazy(() => import("./about"));

const Contact = React.lazy(() => import("./contact"));





const App = () => {
  return (
    <div>
      <React.Suspense fallback={<Loading />}>
        <Routes>

          <Route>

            <Route index element={<Blogs />} />
            {/* <Route path="/blogs" element={<Blogs />} /> */}
            <Route path="/edit" element={<Edit />} />
            <Route path="/create" element={<Create />} />
            <Route path="/register" element={<Register />} />

            <Route path="/blogs/:id" element={<BlogDetails />} /> {/* Add the route for /blogs/:id */}

            <Route path="/admin" element={<Login />} />
            {/* <Route path="/hawa" element={<WriteBlog />} /> */}

            <Route path='/pannel' element={<Pannel />} />

            <Route path="/loading" element={<Loading />} />



            <Route path='/about' element={<About />} />

            <Route path='/contact' element={<Contact />} />

            <Route path='/pannel/users' element={<Cpannel />} />
            <Route path='*' element={<Notfound />} />
            <Route path="/blogs/*" element={<Notfound />} />


          </Route>

        </Routes>
      </React.Suspense>
    </div>
  )
}

export default App