import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TbHome2 } from 'react-icons/tb';
import { IoCreateOutline } from 'react-icons/io5';
import { BiLogOut } from 'react-icons/bi';
import { BiLogIn } from 'react-icons/bi';
import { GrEdit } from 'react-icons/gr';
import { handleSignOut } from './config';

const Navbar = () => {
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate("/create");
  };

  const handleLogin = () => {
    navigate("/admin");
  };

  const handleHome = () => {
    navigate("/");
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('localname');
    handleSignOut();
    navigate('/');
  };

  const handleRegister = () => {
    navigate('/register');
  };

  const handleEdit = () => {
    navigate("/edit");
  };

  return (
    <div>
      <ul className="hidden md:block border-r border-r-gray-100 bg-white opacity-1 ease-in-out duration-500 rounded-md font-bold text-xl hover:cursor-pointer w-[300px] p-10 absolute h-full z-40">
        <li onClick={handleHome} className="p-4 pl-[40px] pt-20 flex gap-4">
          <TbHome2 size={'30px'} />Home
        </li>
        {!localStorage.getItem('token') ? (
          <>
            <li onClick={handleLogin} className="pt-8 p-4 pl-[40px]  flex gap-4">
              <IoCreateOutline size={'30px'} />Create Post
            </li>
            <li onClick={handleRegister} className="pt-8 p-4 pl-[40px]  flex gap-4">
              <BiLogIn size={'30px'} />Register
            </li>
          </>
        ) : (
          <>
            <li onClick={handleCreate} className="pt-8 p-4 pl-[40px]  flex gap-4">
              <IoCreateOutline size={'30px'} />Create Post
            </li>
            <li onClick={handleEdit} className="pt-8 p-4 pl-[40px]  flex gap-5">
              <GrEdit size={'26px'} />Edit Post
            </li>
            <li onClick={handleLogout} className="pt-8 p-4 pl-[40px]  flex gap-4">
              <BiLogOut size={'30px'} />Log Out
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Navbar;
