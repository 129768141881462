// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider, signOut} from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyARSTfUij7lPdHaevomF0yEsVpVUgwSNxc",
  authDomain: "blog-auth-8130f.firebaseapp.com",
  projectId: "blog-auth-8130f",
  storageBucket: "blog-auth-8130f.appspot.com",
  messagingSenderId: "792921425030",
  appId: "1:792921425030:web:05083050daba48f9177d07",
  measurementId: "G-G2TZT7P4WS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth=getAuth(app);
const provider=new GoogleAuthProvider();

const handleSignOut = () => {
  signOut(auth)
    .then(() => {
      // Sign-out successful. Perform any additional actions if needed.
      console.log('User signed out');
    })
    .catch((error) => {
      // An error occurred during sign-out. Handle the error appropriately.
      console.log('Sign-out error:', error);
    });
};

export { auth, provider, handleSignOut };