import React, { useState, useEffect, useRef } from 'react'
import { BiSearch } from 'react-icons/bi';
import { FaSearch } from 'react-icons/fa';
import { TbHome2 } from 'react-icons/tb';

import { TfiMenu, TfiClose } from 'react-icons/tfi';

import { IoCreateOutline } from 'react-icons/io5';

import { GrEdit } from 'react-icons/gr';

import { BiLogOut } from 'react-icons/bi';

import { BiLogIn } from 'react-icons/bi';


import { useNavigate } from 'react-router-dom';
import { handleSignOut } from './config';

const Navbar = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(true);

  const [query, setQuery] = useState("");

  const [blogs, setBlogs] = useState([]);

  const [isClicked, setIsClicked] = useState(false);

  const [isTyping, setIsTyping] = useState(false);

  const [showSearch, setShowSearch] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsClicked(false);
      }
    };

    const handleScroll = () => {
      setIsClicked(false);
      setShowSearch(false);
    };

    const handleTyping = (event) => {
      if (event.target.name !== 'search') {
        return; // Return early if typing is not happening in the search input element
      }
      setIsTyping(true);
      setIsClicked(true);
    };

    const handleTypingEnd = (event) => {
      if (event.target.name !== 'search') {
        return; // Return early if typing is not happening in the search input element
      }
      setIsTyping(false);
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleScroll);
    document.addEventListener('keydown', handleTyping);
    document.addEventListener('keyup', handleTypingEnd);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleScroll);
      document.removeEventListener('keydown', handleTyping);
      document.removeEventListener('keyup', handleTypingEnd);
    };
  }, []);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };


  // useEffect(() => {
  //   fetch("https://vast-plum-panda-tam.cyclic.app/api/v1/blogs")
  //     .then((res) => res.json())
  //     .then((blogs) => {setBlogs(blogs);
  //     });
  // }, [isClicked]);

  useEffect(() => {
    if (isClicked) {
      fetch("https://all-backend-apis.onrender.com/api/v1/blogs")
        .then((res) => res.json())
        .then((blogs) => {
          setBlogs(blogs);
        });
    }
  }, [isClicked]);





  const handleCreate = () => {
    navigate("/create");
  };

  const handleLogin = () => {
    navigate("/admin");
  };

  const handleMenu = () => {
    setMenu(!menu);
  }

  const handleHome = () => {
    navigate('/');
  }

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('localname');
    handleSignOut();
    navigate('/');
  };

  const handleRegister = () => {
    navigate('/register');
  }

  const handleEdit = () => {
    navigate("/edit");
  };

  const handleReadMore = (blog) => {
    navigate(`/blogs/${blog.id}`, { state: { blog } });
  };

  const displaySearch = () => {
    setShowSearch(true);
  }

  const parseHTML = (htmlString) => {
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(htmlString, 'text/html');
    return parsedDocument.body.textContent || '';
  };

  return (
    <div className='border-b-[2px] flex justify-between items-center mx-auto p-[10px] md:p-4 text-xl bg-white'>
      <div className='flex justify-between gap-10 md:pl-[90px]'>
        <div className='pt-2 pl-2 md:hidden' onClick={handleMenu}>{menu ? <TfiMenu color='black' /> : <TfiClose color='#3B49DF' />}</div>

        <h1 className='font-bold text-2xl text-[#3B49DF]'>Blogs</h1>

        <div className='hidden md:flex'>

          <input className='w-[380px] rounded-md h-[40px] pb-[5px] pl-[20px] border-[2px] ' type="search" placeholder='Search...' onChange={(e) => setQuery(e.target.value)} onClick={handleClick} name='search' autoComplete='off' />
          <span className='ml-[-30px] pt-2 '><BiSearch /></span>



          {isClicked && !isTyping &&
            <ul ref={ref} className='left-[210px] top-[70px] z-50 bg-white w-[500px] h-[500px] overflow-x-auto overflow-scroll border-2 pl-3 fixed' >
              {blogs.filter((blog) =>
                blog.title.toLowerCase().includes(query)
              ).map((blog) => (
                <li className='cursor-pointer hover:scale-105 duration-300 border-b-2  h-10 z-90000' key={blog.id} onClick={() => handleReadMore(blog)}>{parseHTML(blog.title).substring(0, 45)}</li>
              ))
              }
            </ul>
          }

        </div>

        {showSearch &&
          <div className='flex'>

            <input className='md:hidden w-[310px] rounded-md h-[50px] fixed top-[90px] left-3 border-[2px] p-4' type="search" placeholder='Search...' onChange={(e) => setQuery(e.target.value)} onClick={handleClick} name='search' />
            {/* <span className='ml-[-30px] pt-2 '><BiSearch /></span> */}



            {isClicked && !isTyping &&
              <ul ref={ref} className='md:hidden  left-[25px] top-[140px] z-50 bg-white w-[220px] h-[500px] overflow-x-auto overflow-scroll border-2 fixed' >
                {blogs.filter((blog) =>
                  blog.title.toLowerCase().includes(query)
                ).map((blog) => (
                  <li className='cursor-pointer hover:scale-105 duration-300 border-b-2  h-15 z-1000' key={blog.id} onClick={() => handleReadMore(blog)}>{parseHTML(blog.title).substring(0, 45)}</li>
                ))
                }
              </ul>
            }

          </div>
        }





      </div>

      <div className='flex pt-2'>
        <div className='flex pt-2 md:hidden' onClick={displaySearch}>
          <span className='ml-[-30px] pt-[10px]  w-[50px] h-[50px] pl-[5px] '><FaSearch /></span>
        </div>
        {localStorage.getItem("token") ?
          <button onClick={handleCreate} className='font-bold border-2 w-[140px] h-[50px] rounded-md border-black text-black hover:scale-105 duration-300'>Create Post</button>
          :
          <button onClick={handleLogin} className='font-bold border-2 w-[140px] h-[50px] rounded-md border-black text-black hover:scale-105 duration-300'>Create Post</button>
        }
      </div>
      <ul className={!menu ? 'fixed left-0 top-0 w-[240px] h-full border-r border-r-gray-400 bg-white opacity-2000 z-50 ease-in-out duration-500 ' : 'ease-in-out duration-500 fixed left-[-100%]'}>
        <>
          <div className='flex'>
            <h1 className='w-full text-3xl font-bold text-[#3B49DF] m-4'>Blogs</h1>
            <span className='p-5' onClick={handleMenu}><TfiClose /></span>
          </div>
          <li onClick={handleHome} className='p-4 pl-[25px] pt-20 flex gap-4 font-bold'><TbHome2 size={'30px'} />Home</li>
          {!localStorage.getItem('token') ?
            <>
              <li onClick={handleLogin} className='pt-8 p-4 pl-[25px]  flex gap-4 font-bold'><IoCreateOutline size={'30px'} />Create Post</li>
              <li onClick={handleRegister} className='pt-8 p-4 pl-[25px]  flex gap-4 font-bold'><BiLogIn size={'30px'} />Register</li>
              {/* <li onClick={handleRegister} className='pt-8 p-4 pl-[50px]  flex gap-4'><BiLogInCircle size={'30px'}/>Register</li> */}
            </> :
            <>
              <li onClick={handleCreate} className='pt-8 p-4 pl-[25px]  flex gap-4 font-bold'><IoCreateOutline size={'30px'} />Create Post</li>
              <li onClick={handleEdit} className='pt-8 p-4 pl-[25px]  flex gap-5 font-bold'><GrEdit size={'26px'} />Edit Post</li>
              <li onClick={handleLogout} className='pt-8 p-4 pl-[25px]  flex gap-4 font-bold'><BiLogOut size={'30px'} />Log Out</li>
            </>
          }
        </>
      </ul>
    </div>

  )
}

export default Navbar